import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

import NavBar from "../../components/common/navBar";
import Footer from "./portfolio_footer";
import Logo from "../../components/common/logo";
import AllProjectsWhite from "../../components/projects/allProjectsWhite";

import INFO from "../../data/user";
import SEO from "../../data/seo";
import "./styles/portfolio_footer.css";
import "./styles/calendar.css";

const Calendar = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);


	const currentSEO = SEO.find((item) => item.page === "projects");
    const sameProject = INFO.projects.find(project => project.title === "PCL 2025 CALENDAR");
    const descriptionImage = sameProject.descriptionImage;
	const descriptionImage2 = sameProject.descriptionImage2;
	const descriptionImage3 = sameProject.descriptionImage3;
	const descriptionImage4 = sameProject.descriptionImage4;
	const descriptionImage5 = sameProject.descriptionImage5;
	const descriptionImage6 = sameProject.descriptionImage6;
	const descriptionImage7 = sameProject.descriptionImage7;
	const descriptionImage8 = sameProject.descriptionImage8;
    const figmaLogo = sameProject.button1;
    const adobeIllustratorLogo = sameProject.button2;
	const adobePhotoshopLogo = sameProject.button3;
    const adobeExpressLogo = sameProject.button4;
	const link1 = sameProject.Link1;
	const link2 = sameProject.Link2;
	const link3 = sameProject.Link3;
	const linkText1 = sameProject.LinkText1;
	



	return (
		<React.Fragment>
			<Helmet>
				<title>{`PCL 2025 CALENDAR | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content-calendar">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="calendar-logo-title">
						<div className="calendar-title">
							PCL 2025 CALENDAR
						</div>
						
					
					</div>
					<div className="calendar-columns">
						{/* Left Column (35% Width) */}
						<div className="calendar-left-column">
						I designed the 2025 desktop and one-page calendars for the Toronto district, aligning them with PCL’s branding and tailoring them to the needs of full-time workers. I developed the theme and visual elements, ensuring they reflected PCL’s professional identity while being practical and visually appealing.
						<br></br><br></br>
						The desktop calendar featured a modern design, making it ideal for desk use, while the one-page calendar provided quick reference at a larger scale for easy visibility.
						</div>
                        
						{/* Right Column (65% Width) */}
						<div className="calendar-right-column">							
							<div className="calendar-software-title">
										DESIGN SOFTWARE
							</div>
							<div className="calendar-software">
                                <a href="https://www.figma.com">
									<img src={figmaLogo} alt="figma" />
								</a>
								<a href="https://www.adobe.com/ca/products/illustrator/campaign/pricing.html?gclid=Cj0KCQjwxeyxBhC7ARIsAC7dS387Mw6AVrVIcwhS1_5NM3VFBFdJrW3Lwf661_aR6r_tdObMWaISwsIaAkaWEALw_wcB&sdid=KKQML&mv=search&ef_id=Cj0KCQjwxeyxBhC7ARIsAC7dS387Mw6AVrVIcwhS1_5NM3VFBFdJrW3Lwf661_aR6r_tdObMWaISwsIaAkaWEALw_wcB:G:s&s_kwcid=AL!3085!3!495846071671!e!!g!!adobe%20illustrator!12231505436!122943072691&mv=search&gad_source=1">
									<img src={adobeIllustratorLogo} alt="illustrator" />
								</a>
							</div>
						</div>
					</div>
					<div className="calendar-content">
						<img
							src={descriptionImage2}
							alt="calendar"
							className="clickable-image wide-image"
						/>
						<img
							src={descriptionImage}
							alt="calendar"
							className="clickable-image half-image"
						/>
						<img
							src={descriptionImage3}
							alt="calendar"
							className="clickable-image half-image"
						/>
						<img
							src={descriptionImage4}
							alt="calendar"
							className="clickable-image wide-image"
						/>
					</div>
					
					<div className="portfolio-page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Calendar;
