import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

import NavBar from "../../components/common/navBar";
import Footer from "./portfolio_footer";
import Logo from "../../components/common/logo";
import AllProjectsWhite from "../../components/projects/allProjectsWhite";

import INFO from "../../data/user";
import SEO from "../../data/seo";
import "./styles/portfolio_footer.css";
import "./styles/uplusme.css";

const UPlusMe = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [isLightboxOpen, setLightboxOpen] = useState(false);

	const currentSEO = SEO.find((item) => item.page === "projects");
    const sameProject = INFO.projects.find(project => project.title === "U+ME - SOCIAL MEDIA");
    const descriptionImage = sameProject.descriptionImage;
	const descriptionImage2 = sameProject.descriptionImage2;
	const descriptionImage3 = sameProject.descriptionImage3;
	const descriptionImage4 = sameProject.descriptionImage4;
	const descriptionImage5 = sameProject.descriptionImage5;
	const descriptionImage6 = sameProject.descriptionImage6;
	const descriptionImage7 = sameProject.descriptionImage7;
	const descriptionImage8 = sameProject.descriptionImage8;
    const figmaLogo = sameProject.button1;
    const adobeIllustratorLogo = sameProject.button2;
	const adobePhotoshopLogo = sameProject.button3;
    const adobeExpressLogo = sameProject.button4;
	const link1 = sameProject.Link1;
	const link2 = sameProject.Link2;
	const link3 = sameProject.Link3;
	const linkText1 = sameProject.LinkText1;
	



	return (
		<React.Fragment>
			<Helmet>
				<title>{`U+ME Social Media | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content-uplusme">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="uplusme-logo-title">
						<div className="uplusme-title">
							U+ME - SOCIAL MEDIA
						</div>
						
						<div className="link-second-wrapper uplusme-title">
							<Link to={link1} className="link-wrapper-big">
								<div className="big-link-icon projlink">
									<FontAwesomeIcon icon={faLink} />
								</div>
							</Link>
							<Link to={link2} className="link-wrapper-big instagram">
								<div className="big-link-icon">
									<FontAwesomeIcon icon={faInstagram} />
								</div>
							</Link>
							<Link to={link3} className="link-wrapper-big youtube">
								<div className="big-link-icon">
									<FontAwesomeIcon icon={faYoutube} />
								</div>
							</Link>
						</div>
					</div>
					<div className="uplusme-columns">
						{/* Left Column (35% Width) */}
						<div className="uplusme-left-column">
						Driven by my passion for dance, I've taken on the roles of graphic designer and social media coordinator for the prominent K-pop dance club “U+ME,” where I'm an active member. I create captivating story posts to promote the new YouTube covers we share and engaging Instagram content brought to life through photo shoots I plan.
<br></br><br></br>
I am responsible for uploading all our videos to YouTube and assist with video editing, especially for performances I take part in. From choreography to visual storytelling, I’m dedicated to showcasing our club's journey and sharing our passion and energy with others through creative and meaningful content.

						</div>
                        
						{/* Right Column (65% Width) */}
						<div className="uplusme-right-column">							
							<div className="uplusme-software-title">
										DESIGN SOFTWARE
							</div>
							<div className="uplusme-software">
                                <a href="https://www.figma.com">
									<img src={figmaLogo} alt="figma" />
								</a>
								<a href="https://www.adobe.com/ca/products/illustrator/campaign/pricing.html?gclid=Cj0KCQjwxeyxBhC7ARIsAC7dS387Mw6AVrVIcwhS1_5NM3VFBFdJrW3Lwf661_aR6r_tdObMWaISwsIaAkaWEALw_wcB&sdid=KKQML&mv=search&ef_id=Cj0KCQjwxeyxBhC7ARIsAC7dS387Mw6AVrVIcwhS1_5NM3VFBFdJrW3Lwf661_aR6r_tdObMWaISwsIaAkaWEALw_wcB:G:s&s_kwcid=AL!3085!3!495846071671!e!!g!!adobe%20illustrator!12231505436!122943072691&mv=search&gad_source=1">
									<img src={adobeIllustratorLogo} alt="illustrator" />
								</a>
								<a href="https://www.adobe.com/ca/products/photoshop/landpa.html?gclid=Cj0KCQjwxeyxBhC7ARIsAC7dS38D4iQW0g-TBLFynjUF723xF5B2yYKKiX7emAOyhU4O8r5CkPIVNIsaAhK4EALw_wcB&sdid=N3PCRP2Q&mv=search&mv2=paidsearch&ef_id=Cj0KCQjwxeyxBhC7ARIsAC7dS38D4iQW0g-TBLFynjUF723xF5B2yYKKiX7emAOyhU4O8r5CkPIVNIsaAhK4EALw_wcB:G:s&s_kwcid=AL!3085!3!673898814215!e!!g!!adobe%20photoshop!20219514620!149761557796&gad_source=1">
									<img src={adobePhotoshopLogo} alt="photoshop" />
								</a>
                                <a href="https://www.adobe.com/express/">
									<img src={adobeExpressLogo} alt="express" />
								</a>
							</div>
						</div>
					</div>
					<div className="uplusme-content">
						<img
							src={descriptionImage}
							alt="uplusme"
							className="clickable-image"
						/>
						<img
							src={descriptionImage2}
							alt="uplusme"
							className="clickable-image"
						/>
						<img
							src={descriptionImage3}
							alt="uplusme"
							className="clickable-image"
						/>
						<img
							src={descriptionImage4}
							alt="uplusme"
							className="clickable-image"
						/>
					</div>
					<div className="uplusme-vertical-images">
					<img
							src={descriptionImage5}
							alt="uplusme"
							className="bottom-image"
						/>
						<img
							src={descriptionImage6}
							alt="uplusme"
							className="bottom-image"
						/>
						<img
							src={descriptionImage7}
							alt="uplusme"
							className="bottom-image"
						/>
						<img
							src={descriptionImage8}
							alt="uplusme"
							className="bottom-image"
						/>
					</div>
					<div className="portfolio-page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default UPlusMe;
