import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../../components/common/navBar";
import Footer from "./portfolio_footer";
import Logo from "../../components/common/logo";
import AllProjectsWhite from "../../components/projects/allProjectsWhite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import INFO from "../../data/user";
import SEO from "../../data/seo";
import "./styles/portfolio_footer.css";
import "./styles/shopglo.css";

const ShopGlo = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");
    const shopGloProject = INFO.projects.find(project => project.title === "SHOP GLO - SKINCARE APP");
    const descriptionImage = shopGloProject.descriptionImage;
	const figmaLogo = shopGloProject.button1;
	const link1 = shopGloProject.Link1;
	const linkText1 = shopGloProject.LinkText1;
	return (
		<React.Fragment>
			<Helmet>
				<title>{`ShopGlo | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content-shopglo">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
                    <div className="shopglo-container">
                        <div className="shopglo-title">
                            SHOP GLO
                        </div>
						<div className="link-second-wrapper">
						<Link to={link1} className="link-wrapper">
							<div className="project-link-icon">
								<FontAwesomeIcon icon={faLink} />
							</div>
							<div className="project-link-text">{linkText1}</div>
						</Link>
					</div>
                        <div className="shopglo-content">
                            <img src={descriptionImage} alt="shopglo" />
							<div className="shopglo-software-title">
								DESIGN SOFTWARE
							</div>
							<div className="shopglo-software">
								<a href="https://www.figma.com/">
									<img src={figmaLogo} alt="figmalogo" />
								</a>
							</div>
							I teamed up to create a skincare and makeup app that simplifies buying international products and discovering new ones. I collaborated with two classmates on designing and prototyping the app.
							<br></br><br></br>
							Our goal was to make shopping for international beauty products easy and convenient. The app features personalized recommendations, reviews, translations for product descriptions, easy purchasing, and community engagement.
							<br></br><br></br>
							We focused on making the app user-friendly and inspiring users to explore global beauty products in a convenient way.
                        </div>
                    </div>
					<div className="portfolio-page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ShopGlo;
