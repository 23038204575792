import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

import NavBar from "../../components/common/navBar";
import Footer from "./portfolio_footer";
import Logo from "../../components/common/logo";

import INFO from "../../data/user";
import SEO from "../../data/seo";
import "./styles/portfolio_footer.css";
import "./styles/hoardinggraphic.css";

const HoardingGraphic = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");
    const sameProject = INFO.projects.find(project => project.title === "PCL HOARDING GRAPHIC");
	const descriptionImage5 = sameProject.descriptionImage;
	const descriptionImage6 = sameProject.descriptionImage2;
	const descriptionImage7 = sameProject.descriptionImage3;
	const descriptionImage8 = sameProject.descriptionImage2;
    const adobeInDesignLogo = sameProject.button1;
    const adobeIllustratorLogo = sameProject.button2;

	return (
		<React.Fragment>
			<Helmet>
				<title>{`PLC HOARDING GRAPHIC | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content-hoardinggraphic">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="hoardinggraphic-logo-title">
						<div className="hoardinggraphic-title">
							PCL HOARDING GRAPHIC
						</div>
						
				
					</div>
					<div className="hoardinggraphic-columns">
						{/* Left Column (35% Width) */}
						<div className="hoardinggraphic-left-column">
						Designed a series of visually cohesive hoarding graphics for PCL Construction’s project with a client. The objective was to create designs that blended seamlessly with existing visuals, enhancing the construction site's aesthetic without resembling advertisements. This project required a thoughtful approach to subtly integrate branding while supporting the client’s construction efforts.
						</div>
					</div>
					<div className="hoardinggraphic-software-title">
										DESIGN SOFTWARE
							</div>
							<div className="hoardinggraphic-software">
                                <a href="https://www.adobe.com/ca/products/indesign.html">
									<img src={adobeInDesignLogo} alt="adobeInDesignLogo" />
								</a>
								<a href="https://www.adobe.com/ca/products/illustrator/campaign/pricing.html?gclid=Cj0KCQjwxeyxBhC7ARIsAC7dS387Mw6AVrVIcwhS1_5NM3VFBFdJrW3Lwf661_aR6r_tdObMWaISwsIaAkaWEALw_wcB&sdid=KKQML&mv=search&ef_id=Cj0KCQjwxeyxBhC7ARIsAC7dS387Mw6AVrVIcwhS1_5NM3VFBFdJrW3Lwf661_aR6r_tdObMWaISwsIaAkaWEALw_wcB:G:s&s_kwcid=AL!3085!3!495846071671!e!!g!!adobe%20illustrator!12231505436!122943072691&mv=search&gad_source=1">
									<img src={adobeIllustratorLogo} alt="illustrator" />
								</a>
							</div>
					<div className="hoardinggraphic-images">
					<img
							src={descriptionImage6}
							alt="hoardinggraphic"
							className="bottom-image"
						/>
					</div>
					<div className="portfolio-page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default HoardingGraphic;
